import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";
import { Button, ButtonToolbar, DateRangePicker, Input, SelectPicker } from "rsuite";
import { ChevronRightIcon, PlusIcon } from "@heroicons/react/outline";
import { BadgeCheckIcon, CheckCircleIcon } from "@heroicons/react/solid";
import { omit } from "lodash";
import { useDidUpdate } from "@gcm/libs/hooks";

import UserPanel from "@gcm/pages/Users/components/Panel";

function Users() {
  const [saveUserState, setSaveUserState] = useState({
    isOpen: false,
    data: null,
  });

  const [filterData, setFilterData] = useState({
    limit: 10,
    page: 1,
  });

  const { data, loading, error } = useQuery(gql`
    query GetUsers($filter: UserFilter) {
      users(filter: $filter) {
        id
        active
        deleted
        phone {
          id
          countryCode
          value
          verified
        }
        profile {
          id
          firstName
          lastName
          email
          emailVerified
        }
      }
    }
  `, {
    variables: {
      filter: {
        limit: filterData.limit,
        offset: (filterData.page - 1) * filterData.limit
      },
    },
  });

  function renderTable(users) {
    if (users?.length === 0) return <div>No users found.</div>

    return (
      <div className="table">
        <div className="row header">
          <div className="col-span-3">Name</div>
          <div className="col-span-3">Phone</div>
          <div className="col-span-5">Email</div>
          <div className="col-span-1" />
        </div>
        <div className="body">
          {
            users.map((user) => {
              return (
                <div key={`region-${user.id}`} className="row">
                  <div className="col-span-3">
                    {user.active
                      ? <CheckCircleIcon className="w-6 h-6 inline-block mr-2" color="green" />
                      : <span className="w-6 h-6" />
                    }
                    {`${user.profile?.firstName || ""} ${user.profile?.lastName || ""}`.trim()}
                  </div>
                  <div className="col-span-3">
                    {/* {user.phone?.verified
                      ? <BadgeCheckIcon className="w-6 h-6 inline-block mr-2" color="green" />
                      : <span className="w-6 h-6" />
                    } */}
                    {user.phone ? `${user.phone?.countryCode} ${user.phone?.value}`.trim() : ""}
                  </div>
                  <div className="col-span-5">
                    {user.profile?.emailVerified
                      ? <BadgeCheckIcon className="w-6 h-6 inline-block mr-2" color="green" />
                      : <span className="w-6 h-6" />
                    }
                    {`${user.profile?.email || ""}`}
                  </div>
                  <div className="col-span-1 justify-self-end">
                    {/* <button
                      className="transparent"
                      onClick={() => {
                        setSaveUserState({
                          ...saveUserState,
                          isOpen: true,
                          data: omit(user, ["__typename"]),
                        })
                      }}>
                      <ChevronRightIcon className="w-6 h-6" />
                    </button> */}
                  </div>
                </div>
              )
            })
          }
        </div>
        <div className="footer">
          <ButtonToolbar>
            <Button
              size="xs" appearance="ghost"
              disabled={filterData.page > 1 ? false : true}
              onClick={() => {
                setFilterData({
                  ...filterData,
                  page: filterData.page - 1,
                });
              }}
            >Back</Button>
            <Button
              size="xs" appearance="ghost"
              disabled={filterData.limit > data.users.length}
              onClick={() => {
                setFilterData({
                  ...filterData,
                  page: filterData.page + 1,
                });
              }}
            >Next</Button>
          </ButtonToolbar>
        </div>
      </div>
    );
  }

  if (error) return null;
  if (loading) return null;

  const { users } = data;

  if (!users || users.length === 0) return <div>No users</div>

  return (
    <>
      <div className="p-8">
        <div className="flex justify-between items-center mb-6">
          <h1>Users</h1>
          {/* <div>
            <a href="/users/create" onClick={(e) => {
              e.preventDefault();
              setSaveUserState({ ...saveUserState, isOpen: true, data: null });
            }}>
              <PlusIcon className="w-6 h-6" />
            </a>
          </div> */}
        </div>
        <div>
          {renderTable(users)}
        </div>
      </div>

      {/* <UserPanel user={saveUserState.data} isOpen={saveUserState.isOpen} close={() => {
        setSaveUserState({ ...saveUserState, isOpen: false })
      }} /> */}
    </>
  )
};

function UserTable({ users }) {
  return null;
}

export default Users;
