import { useAuth0 } from "@auth0/auth0-react";
import { intersection } from "lodash";

function isInRole(roles, userRoles) {
  return intersection(roles, userRoles).length > 0;
}

let _user;

export default _user ||
  (() => {
    const { user } = useAuth0();

    _user = {
      roles: user[`${CONFIG.AUTH0_NAMESPACE}/roles`],
      isInRole: (roles) => isInRole(roles, user[`${CONFIG.AUTH0_NAMESPACE}/roles`]),
      ...user,
    }

    return _user;
  });
