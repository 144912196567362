import { gql, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { CheckCircleIcon, ColorSwatchIcon, LinkIcon, MailIcon, PencilAltIcon, PhoneIcon, PlusIcon, TagIcon, XCircleIcon } from "@heroicons/react/outline";
import SlidingPanel from "react-sliding-side-panel";
import { useState } from "react";
import DealForm from "@gcm/components/organisms/DealForm";
import { Color } from "@gcm/libs/tailwind";

import BusinessForm from "../components/BusinessForm";
import LocationForm from "../components/LocationForm";
import DealList from "../components/DealList";
import LocationList from "../components/LocationList";
import Hero from "../components/Hero";
import Logo from "../components/Logo";
import Gallery from "../components/Gallery";
import User from "@gcm/libs/user";

function BusinessDetail() {
  const { id } = useParams();
  const [viewState, setViewState] = useState({
    panel: null,
    data: null,
  });

  const user = User();

  const { data: viewData, loading, error } = useQuery(gql`
    query GetBusiness($id: String!) {
      business(id: $id) {
        id
        name
        active
        website
        email
        phone
        hero
        heroSquare
        logo
        experience
        type
        category {
          id
          name
          experience
        }
        tags {
          id
          name
        }
        locations {
          id
          name
        }
      }
    }
  `, {
    variables: {
      id
    }
  });

  function renderTags(tags) {
    if (!tags || tags.length === 0) {
      return <span></span>;
    }

    return (
      <>
        {tags.map(tag => {
          return (
            <span key={`tag-${tag.id}`} className="pill lightGray">{tag.name}</span>
          )
        })}
      </>
    )
  }

  if (loading) return null;
  if (error) return <div>Error</div>;
  if (!viewData?.business) return <div>Not found</div>;

  const { business } = viewData;

  return (
    <>
      <div className="p-12 grid grid-cols-12">
        <div className="col-span-7">
          <div>
            <div className="flex flex-row items-center text-sm font-bold">
              {business.active ?
                <><CheckCircleIcon color="green" className="w-6 h-6 mr-1" /> Active</> :
                <><XCircleIcon color="red" className="w-6 h-6 mr-1" /> Inactive</>
              }
            </div>
            <h1>
              {business.name}
            </h1>
            <div className="flex mb-2 text-sm">
              <div className="flex items-center mr-3">
                <LinkIcon className="w-4 h-4 mr-2" />
                {business.website || "N/A"}
              </div>
              <div className="flex items-center mr-3">
                <MailIcon className="w-4 h-4 mr-2" />
                {business.email || "N/A"}
              </div>
              <div className="flex items-center">
                <PhoneIcon className="w-4 h-4 mr-2" />
                {business.phone || "N/A"}
              </div>
            </div>
            <div className="flex space-x-2 mb-2">
              <span className="pill">{business.category.experience}</span>
              <span className="pill">{business.category.name}</span>
            </div>
            <div className="flex space-x-2 items-center">
              <div className="pill lightGray flex items-center">
                <TagIcon className="w-4 h-4 mr-2" color={Color("darkBlue")} /> Tags
              </div>
              {renderTags(business.tags)}
            </div>
          </div>
        </div>

        {user.isInRole(["ADMIN"]) && (
          <div className="col-span-5 justify-self-end flex items-center">
            <button
              className="transparent"
              onClick={() => {
                setViewState({
                  ...viewState,
                  panel: "BUSINESS"
                })
              }}
            >
              <PencilAltIcon className="w-6 h-6" />
            </button>
          </div>
        )}
      </div>


      <div className="px-12 mb-8 flex">
        <div className="mr-4">
          <Logo business={business.id} image={business.logo} />
        </div>
        <div className="mr-4">
          <Hero business={business.id} image={business.hero} aspect="landscape" />
        </div>
        <div className="mr-4">
          <Hero business={business.id} image={business.heroSquare} aspect="square" />
        </div>
        <div className="mr-4">
          {/* <Gallery /> */}
        </div>
      </div>

      <div className="px-12 grid grid-cols-12 space-x-5">
        <div className="col-span-6">
          <div className="border border-coolGray rounded-xl shadow-md p-5 bg-white">
            <h3 className="flex justify-between items-center">
              Locations
              {user.isInRole(["ADMIN"]) && (
                <button className="transparent" onClick={() => setViewState({ ...viewState, panel: "LOCATION" })}>
                  <PlusIcon className="w-6 h-6" />
                </button>
              )}
            </h3>
            <LocationList business={business.id} locations={business.locations} />
          </div>
        </div>
        <div className="col-span-6">
          <div className="border border-coolGray rounded-xl shadow-md p-5 bg-white">
            <h3 className="flex justify-between items-center">
              Deals
              {user.isInRole(["ADMIN"]) && (
                <button className="transparent" onClick={() => setViewState({ ...viewState, panel: "DEAL" })}>
                  <PlusIcon className="w-6 h-6" />
                </button>
              )}
            </h3>
            <DealList business={business.id} refetchQueries={["GetBusinessDeals"]} />
          </div>
        </div>
      </div>

      {user.isInRole(["ADMIN"]) && (
        <SlidingPanel
          type={'right'}
          isOpen={viewState.panel !== null}
          size={30}
        >
          {viewState.panel === "BUSINESS" &&
            <BusinessForm
              id={business.id}
              category={business.category.id}
              data={business}
              onSaved={() => setViewState({ ...viewState, panel: null })}
              handleClose={() => setViewState({ ...viewState, panel: null })}
            />
          }
          {viewState.panel === "LOCATION" &&
            <LocationForm
              business={business.id}
              onSaved={() => setViewState({ ...viewState, panel: null, data: null })}
              handleClose={() => setViewState({ ...viewState, panel: null, data: null })}
            />
          }
          {viewState.panel === "DEAL" &&
            <DealForm
              data={viewState.data}
              business={business.id}
              locations={business.locations}
              refetchQueries={["GetBusiness", "GetBusinessDeals"]}
              onSaved={() => setViewState({ ...viewState, panel: null, data: null })}
              handleClose={() => setViewState({ ...viewState, panel: null, data: null })}
            />
          }
        </SlidingPanel>
      )}
    </>
  )
}

export default BusinessDetail;
