import { gql, useApolloClient, useQuery } from "@apollo/client";
import { useMemo, useState } from "react";
import { ChevronRightIcon, PlusIcon, XIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/solid";
import SlidingPanel from "react-sliding-side-panel";
import { useNavigate } from "react-router-dom";
import { Button, ButtonToolbar, IconButton, Input, InputGroup } from "rsuite";
import RPlusIcon from "@rsuite/icons/Plus";
import SearchIcon from "@rsuite/icons/Search";
import { debounce, get } from "lodash";
import { useDidUpdate } from "@gcm/libs/hooks";
import User from "@gcm/libs/user";
import { useAuth0 } from "@auth0/auth0-react";

import Form from "../components/BusinessForm";

function TableBody({ loading, data }) {
  const navigate = useNavigate();
  const user = User();

  if (loading) {
    return <div className="p-5">Loading...</div>
  }

  return data.businesses.data.map((business) => {
    return (
      <div
        key={`business-${business.id}`}
        className="row cursor-pointer"
        onClick={() => {
          navigate(`/business/${business.id}`);
        }}
      >
        <div className="col-span-8">
          {business.name}
        </div>
        <div className="col-span-2">
          {business.category.name}
        </div>
        <div className="col-span-1 justify-self-end">
          {business.active
            ? <CheckCircleIcon className="w-6 h-6 inline-block mr-2" color="green" />
            : <span className="w-6 h-6" />
          }
        </div>
        <div className="col-span-1 justify-self-end">
          <ChevronRightIcon className="w-6 h-6" />
        </div>
      </div>
    )
  })
}

function Pagination({ page, limit, loading, data, back, next }) {
  if (loading) {
    return (
      <div>...</div>
    )
  }

  const totalPages = Math.ceil(data.businesses.pagingInfo.count / limit);

  return (
    <ButtonToolbar>
      <Button
        size="xs" appearance="ghost"
        disabled={page > 1 ? false : true}
        onClick={() => {
          back();
        }}
      >Back</Button>
      <Button
        size="xs" appearance="ghost"
        disabled={page >= totalPages}
        onClick={() => {
          next();
        }}
      >Next</Button>
    </ButtonToolbar>
  )
}

function BusinessTable({ search }) {
  const [filterData, setFilterData] = useState({
    search,
    page: 1,
    limit: 10,
  });

  useDidUpdate(() => {
    setFilterData({
      ...filterData,
      page: 1,
      search,
    })
  }, [search]);

  const { data, loading, error } = useQuery(gql`
    query GetBusinesses($filter: BusinessFilter) {
      businesses(filter: $filter) {
        data {
          id
          name
          active
          category {
            id
            name
          }
        }
        pagingInfo {
          count
        }
      }
    }
  `, {
    variables: {
      filter: {
        search: filterData.search,
        offset: (filterData.page - 1) * filterData.limit,
        limit: filterData.limit
      }
    }
  });

  if (error) {
    return (
      <div className="bg-white h-40 flex justify-center items-center rounded-md shadow-lg">
        Unable to list businesses.
      </div>
    );
  }

  const records = get(data, "businesses.data", []);

  if (!loading && filterData.page <= 1 && records.length === 0) {
    return (
      <div className="flex w-full">
        <div>
          <h2>Create Your First Business</h2>
          <button onClick={() => {
            setViewState({ ...viewState, isOpen: true });
          }}>Create Business</button>
        </div>
      </div>
    )
  }

  return (
    <div className="table">
      <div className="row header">
        <div className="col-span-8">Name</div>
        <div className="col-span-2">Category</div>
        <div className="col-span-1" />
        <div className="col-span-1" />
      </div>
      <div className="body">
        <TableBody loading={loading} data={data} />
      </div>
      <div className="footer">
        <Pagination
          loading={loading} data={data} page={filterData.page} limit={filterData.limit}
          back={() => {
            setFilterData({
              ...filterData,
              page: filterData.page - 1,
            });
          }}
          next={() => {
            setFilterData({
              ...filterData,
              page: filterData.page + 1,
            });
          }}
        />
      </div>

    </div>
  );
}

function BusinessList() {
  const user = User();

  const [search, setSearch] = useState("");
  const handleSearchChange = (val) => {
    setSearch(val);
  }
  const debouncedSearchHandler = useMemo(() => debounce(handleSearchChange, 500), []);

  const [viewState, setViewState] = useState({
    isOpen: false,
    selectedId: 0,
    data: null,
  });

  return (
    <>
      <div>
        <div className="flex justify-between items-center mb-6">
          <div>
            <h3>Business</h3>
            <div className="w-20 bg-blue h-3 mb-5" />
          </div>
          <div className="flex">
            <InputGroup inside className="mr-5">
              <Input placeholder={""} onChange={debouncedSearchHandler} />
              <InputGroup.Button>
                <SearchIcon />
              </InputGroup.Button>
            </InputGroup>
            {user.isInRole(["ADMIN"]) && (
              <IconButton
                appearance="primary" color="green" icon={<RPlusIcon />}
                onClick={(e) => {
                  e.preventDefault();
                  setViewState({ ...viewState, isOpen: true, data: null });
                }}
              >Add</IconButton>
            )}
          </div>
        </div>
        <div>
          <BusinessTable search={search} />
        </div>
      </div>
      {user.isInRole(["ADMIN"]) && (
        <SlidingPanel
          type={'right'}
          isOpen={viewState.isOpen}
          size={30}
        >
          <>
            <div className="absolute right-6 top-6">
              <button className={`transparent`} onClick={() => setViewState({ ...viewState, isOpen: false })}><XIcon className="h-6 w-6" /></button>
            </div>
            <div className="">
              <Form
                id={viewState.selectedId}
                data={viewState.data}
                onSaved={() => setViewState({ ...viewState, isOpen: false, data: null, selectedId: 0 })}
                handleClose={() => setViewState({ ...viewState, isOpen: false, data: null, selectedId: 0 })}
              />
            </div>
          </>
        </SlidingPanel>
      )}

    </>
  )
}

export default BusinessList;
