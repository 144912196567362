import React, { useState } from "react";
import { gql, useQuery } from "@apollo/client";

import { useAuth0 } from "@auth0/auth0-react";
import { Button, ButtonToolbar, DateRangePicker, SelectPicker } from "rsuite";
import { DateTime } from "luxon";
import { formatMoney } from "@gcm/libs/utils";
import { Advertisement } from "@gcm/components/ui";
import { useDidUpdate } from "@gcm/libs/hooks";
import User from "@gcm/libs/user";

const today = DateTime.now();
const yesterday = today.minus({ days: 1 });
const firstDate = DateTime.fromObject({ year: 2022, month: 5, day: 25 });

const predefinedRanges = [
  {
    label: "Today",
    value: [today.startOf("day").toJSDate(), today.endOf("day").toJSDate()],
    placement: "left"
  },
  {
    label: "Yesterday",
    value: [yesterday.startOf("day").toJSDate(), yesterday.endOf("day").toJSDate()],
    placement: "left"
  },
  {
    label: "This week",
    value: [today.startOf("week").toJSDate(), today.endOf("week").toJSDate()],
    placement: "left"
  },
  {
    label: "Last week",
    value: [today.startOf("week").minus({ day: 7 }).toJSDate(), today.endOf("week").minus({ day: 7 }).toJSDate()],
    placement: "left"
  },
  {
    label: "Last 7 days",
    value: [today.minus({ days: 6 }).toJSDate(), today.toJSDate()],
    placement: "left"
  },
  {
    label: "Last 30 days",
    value: [today.minus({ days: 30 }).toJSDate(), today.toJSDate()],
    placement: "left"
  },
  {
    label: "This month",
    value: [today.startOf("month").toJSDate(), today.endOf("month").toJSDate()],
    placement: "left"
  },
  {
    label: "Last month",
    value: [today.minus({ month: 1 }).startOf("month").toJSDate(), today.minus({ month: 1 }).endOf("month").toJSDate()],
    placement: "left"
  },
  {
    label: "This year",
    value: [today.startOf("year").toJSDate(), today.toJSDate()],
    placement: "left"
  },
  {
    label: "Last year",
    value: [today.minus({ year: 1 }).startOf("year").toJSDate(), today.minus({ year: 1 }).endOf("year").toJSDate()],
    placement: "left"
  },
  {
    label: "All time",
    value: [firstDate.toJSDate(), today.toJSDate()],
    placement: "left"
  }
];

function BusinessSelector({ user, onChange }) {
  const { data, loading, error } = useQuery(gql`
    query GetManagedBusinesses($user: UUID!) {
      managedBusinesses(user: $user) {
        id
        name
      }
    },
    
  `, {
    variables: {
      user: user["https://giftcertificatesandmore.com/id"]
    }
  });

  if (loading) return null;
  if (error) return null;

  const businesses = data.managedBusinesses.map((business) => {
    return {
      value: business.id,
      label: business.name,
    }
  });

  return (
    <SelectPicker
      style={{ width: "240px" }}
      data={businesses}
      onChange={(selected) => onChange(selected)}
    />
  )
}

function RedemptionStats({ startDate, endDate, business }) {
  const user = User();

  const { data, loading, error } = useQuery(gql`
    query GetRedemptionsStats($business: UUID, $startDate: DateTime, $endDate: DateTime) {
      redemptionStats(business: $business, startDate: $startDate, endDate: $endDate) {
        numberCustomers
        numberRedemptions
        totalSaved
        today
        week
        month
      }
    }
    `, {
    variables: {
      business,
      startDate,
      endDate,
    }
  });

  if (error) {
    return (
      <div>
        <div className="grid grid-cols-3 gap-4 mb-5">
          <div className="py-10 text-center rounded-md bg-white shadow-lg">
            <div className="font-bold text-warmGray pb-4">Redemptions</div>
            <div className="font-bold text-darkGray text-5xl">Error</div>
          </div>
        </div>
      </div>
    )
  }

  let stats = {
    numberCustomers: null,
    numberRedemptions: 0,
    totalSaved: null,
    today: 0,
    week: 0,
    month: 0,
  };

  if (loading) {
    return (
      <div className="grid grid-cols-3 gap-4 mb-5">
        {user.isInRole(["ADMIN", "FRANCHISE_ADMIN"]) && (
          <>
            <div className="grid grid-cols-2 col-span-2 gap-4">
              <div className="py-10 text-center rounded-md bg-white shadow-lg ">
                <div className="font-bold text-warmGray pb-4">Customers</div>
                <div className="font-bold text-darkGray text-5xl">-</div>
              </div>

              <div className="py-10 text-center rounded-md bg-white shadow-lg ">
                <div className="font-bold text-warmGray pb-4">Total Saved</div>
                <div className="font-bold text-darkGray text-5xl">-</div>
              </div>
            </div>
            <div />
          </>
        )}
        <div className="py-10 text-center rounded-md bg-white shadow-lg ">
          <div className="font-bold text-warmGray pb-4">Redemptions</div>
          <div className="font-bold text-darkGray text-5xl">-</div>
        </div>
        <div className="col-span-2 grid grid-cols-3 gap-4 my-5">
          <div className="border-r border-gray flex flex-col text-center justify-center">
            <div className="font-bold text-warmGray pb-4">Today</div>
            <div className="font-bold text-darkGray text-4xl">-</div>
          </div>
          <div className="border-r border-gray flex flex-col text-center justify-center">
            <div className="font-bold text-warmGray pb-4">Week</div>
            <div className="font-bold text-darkGray text-4xl">-</div>
          </div>
          <div className="flex flex-col text-center justify-center">
            <div className="font-bold text-warmGray pb-4">Month</div>
            <div className="font-bold text-darkGray text-4xl">-</div>
          </div>
        </div>
      </div>
    )
  }

  if (data.redemptionStats) stats = {
    numberCustomers: data.redemptionStats.numberCustomers,
    numberRedemptions: data.redemptionStats.numberRedemptions,
    totalSaved: data.redemptionStats.totalSaved,
    today: data.redemptionStats.today,
    week: data.redemptionStats.week,
    month: data.redemptionStats.month,
  }

  return (
    <div className="grid grid-cols-3 gap-4 mb-5">
      {user.isInRole(["ADMIN", "FRANCHISE_ADMIN"]) && (
        <>
          <div className="grid grid-cols-2 col-span-2 gap-4">
            <div className="py-10 text-center rounded-md bg-white shadow-lg ">
              <div className="font-bold text-warmGray pb-4">Customers</div>
              <div className="font-bold text-darkGray text-5xl">{stats.numberCustomers}</div>
            </div>

            <div className="py-10 text-center rounded-md bg-white shadow-lg ">
              <div className="font-bold text-warmGray pb-4">Total Saved</div>
              <div className="font-bold text-darkGray text-5xl">${formatMoney(stats.totalSaved)}</div>
            </div>
          </div>
          <div />
        </>
      )}
      <div className="py-10 text-center rounded-md bg-white shadow-lg ">
        <div className="font-bold text-warmGray pb-4">Redemptions</div>
        <div className="font-bold text-darkGray text-5xl">{stats.numberRedemptions}</div>
      </div>
      <div className="col-span-2 grid grid-cols-3 gap-4 my-5">
        <div className="border-r border-gray flex flex-col text-center justify-center">
          <div className="font-bold text-warmGray pb-4">Today</div>
          <div className="font-bold text-darkGray text-4xl">{stats.today}</div>
        </div>
        <div className="border-r border-gray flex flex-col text-center justify-center">
          <div className="font-bold text-warmGray pb-4">Week</div>
          <div className="font-bold text-darkGray text-4xl">{stats.week}</div>
        </div>
        <div className="flex flex-col text-center justify-center">
          <div className="font-bold text-warmGray pb-4">Month</div>
          <div className="font-bold text-darkGray text-4xl">{stats.month}</div>
        </div>
      </div>


    </div>
  )
}

function RedemptionTableBody({ records, page }) {
  if (page > 1 && records.length === 0) {
    return (
      <div className="p-5">End of records.</div>
    )
  } else if (records.length === 0) {
    return (
      <div className="p-5">No records found.</div>
    )
  } else {
    return (
      <>
        {
          records.map((redemption) => {
            const redeemedAt = DateTime.fromISO(redemption.redeemedAt);

            return (
              <div
                key={`deal-${redemption.id}`}
                className="row cursor-pointer"
              >
                <div className="col-span-2">
                  {redeemedAt.toLocaleString(DateTime.DATE_FULL)}<br />
                  <span className="text-sm">{redeemedAt.toLocaleString(DateTime.TIME_SIMPLE)}</span>
                </div>
                <div className="col-span-3">
                  {redemption.user.profile.firstName} {redemption.user.profile.lastName}
                </div>
                <div className="col-span-3">
                  {redemption.location.name}<br />
                  <span className="text-sm">{redemption.location.business.name}</span>
                </div>
                <div className="col-span-1">
                  {redemption.deal.value}
                </div>
                <div className="col-span-3">
                  {redemption.deal.name}
                </div>
              </div>
            )
          })
        }
      </>
    )
  }
}

function RedemptionTable({ startDate, endDate, business }) {
  const [filterData, setFilterData] = useState({
    startDate: startDate,
    endDate: endDate,
    business: null,
    limit: 5,
    page: 1,
  });

  useDidUpdate(() => {
    setFilterData({
      ...filterData,
      page: 1,
      business,
      startDate,
      endDate,
    })
  }, [business, startDate, endDate]);

  const { data, loading, error } = useQuery(gql`
    query GetRedemptions($business: UUID, $startDate: DateTime, $endDate: DateTime, $filter: RedemptionFilter) {
      redemptions(business: $business, startDate: $startDate, endDate: $endDate, filter: $filter) {
        id
        redeemedAt
        deal {
          id
          name
          value
        }
        location {
          id
          name
          business {
            id
            name
          }
        } 
        user {
          id
          profile {
            id
            firstName
            lastName
          }
        }
      }
    }
  `, {
    variables: {
      business: filterData.business,
      startDate: filterData.startDate,
      endDate: filterData.endDate,
      filter: {
        limit: filterData.limit,
        offset: (filterData.page - 1) * filterData.limit
      }
    }
  });

  if (loading) {
    return (
      <div className="bg-white h-40 flex justify-center items-center rounded-md shadow-lg">
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-white h-40 flex justify-center items-center rounded-md shadow-lg">
        No redemptions found.
      </div>
    );
  }

  return (
    <div className="table">
      <div className="row header">
        <div className="col-span-2">Date</div>
        <div className="col-span-3">User</div>
        <div className="col-span-3">Location</div>
        <div className="col-span-1">Value</div>
        <div className="col-span-3">Name</div>
      </div>
      <div className="body">
        <RedemptionTableBody records={data.redemptions} page={filterData.page} />
      </div>

      <div className="footer">
        <ButtonToolbar>
          <Button
            size="xs" appearance="ghost"
            disabled={filterData.page > 1 ? false : true}
            onClick={() => {
              setFilterData({
                ...filterData,
                page: filterData.page - 1,
              });
            }}
          >Back</Button>
          <Button
            size="xs" appearance="ghost"
            disabled={filterData.limit > data.redemptions.length}
            onClick={() => {
              setFilterData({
                ...filterData,
                page: filterData.page + 1,
              });
            }}
          >Next</Button>
        </ButtonToolbar>
      </div>
    </div>
  );
}

function Dashboard() {
  const { user } = useAuth0();
  const [filterData, setFilterData] = useState({
    startDate: today.minus({ days: 6 }).toJSDate(),
    endDate: today.toJSDate(),
    business: null,
  });

  return (
    <>
      <h3>Overview</h3>
      <div className="w-20 bg-blue h-3 mb-5" />
      <div className="flex">
        <div className="flex-1 mr-10">
          <div className="flex bg-white p-4 rounded-md mb-5 shadow-lg">
            <div className="mr-4">
              <div className="font-bold pb-2">Time Period</div>
              <DateRangePicker
                cleanable={false}
                character=" to "
                style={{ width: 240 }}
                ranges={predefinedRanges}
                defaultValue={[today.minus({ days: 6 }).toJSDate(), today.toJSDate()]}
                onChange={(value) => {
                  setFilterData({
                    ...filterData,
                    startDate: value[0],
                    endDate: value[1],
                  });
                }}
              />
            </div>
            <div>
              <div className="font-bold pb-2">Business</div>
              <BusinessSelector
                user={user}
                onChange={(selected) => {
                  setFilterData({
                    ...filterData,
                    business: selected
                  });
                }}
              />
            </div>
          </div>
          <RedemptionStats
            business={filterData.business}
            startDate={filterData.startDate}
            endDate={filterData.endDate}
          />
          <div>
            <RedemptionTable
              business={filterData.business}
              startDate={filterData.startDate}
              endDate={filterData.endDate}
            />
          </div>
        </div>
        <div style={{ width: "160px" }}>
          <Advertisement />
        </div>
      </div>
    </>
  )
};

export default Dashboard;
